import { useSettingsStore } from '~/stores/settings';

export function useSettingValue<T>(name: string): T | null | any {
    const data = useSettingsStore();
    const setting = data.settings.find((s) => s.name === name);

    if (setting) {
        return setting.value as T;
    }

    return null;
}
